import React from "react";
import linkImg from "../assets/open_link.svg";

import mixpanel from "mixpanel-browser";

function Card({ title, url, type }) {
  const linkOpened = () => {
    mixpanel.track(`${type}`);
  };
  return (
    <div>
      <a href={url} target="#" onClick={linkOpened}>
        <div className={`card ${type}`}>
          <div className="card1stRow">
            <h2>
              {title}
              <span>
                <img className="imgSmall" src={linkImg} alt="open link"/>
              </span>
            </h2>
          </div>
          <h3>{type}</h3>
        </div>
      </a>
    </div>
  );
}
export default Card;
