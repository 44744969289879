import React, { useState } from "react";

import axios from "axios";

import cross from "../assets/cross_icon.svg";

function Contribute({ popup, setPopup }) {
  // form states
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [category, setCategory] = useState("");
  const [email, setEmail] = useState("");

  // submit event
  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(title, url, category, email);

    const data = {
      Title: title,
      Url: url,
      Category: category,
      Email: email,
    };

    axios
      .post(
        "https://sheet.best/api/sheets/0711f927-2b12-4343-8a12-5d820d3baf84",
        data
      )
      .then((Response) => {});

    // clearing form fields
    setTitle("");
    setUrl("");
    setCategory("");
    document.getElementsByName("category")[0].value = "";
    setEmail("");
  };

  function selectCat() {
    let input = document.getElementsByName("category")[0].value;
    setCategory(input);
  }

  return (
    <div className="siteWrapper">
      {/* <div> */}
      <div className="formPopup hide" id="formPopup">
        <div>
          <div className="formTopContainer">
            <div className="form1stRow">
              <h2 className="formTitle">Contribute</h2>
              <button type="reset" className="closeBtn">
                <img
                  src={cross}
                  onClick={() => setPopup(!popup) }
                  alt="close button"
                />
              </button>
            </div>
            <h3>Feel free to suggest a link below</h3>
          </div>
          <div className="formBottomContainer">
            <form autoComplete="off" onSubmit={handleSubmit}>
              <label>Content Title</label>
              <br></br>
              <input
                type="text"
                placeholder="Title for the content"
                required
                onChange={(e) => setTitle(e.target.value)}
                value={title}
              ></input>
              <br></br>
              <label>Link</label>
              <br></br>

              <input
                type="url"
                placeholder="Paste the url"
                required
                onChange={(e) => setUrl(e.target.value)}
                value={url}
              ></input>
              <br></br>
              <label>Category</label>
              <br></br>
              <input
                type="text"
                list="category"
                name="category"
                placeholder="Enter category"
                required
                onChange={() => selectCat()}
              />

              <datalist id="category">
                <option key="Random" value="Random" />
                <option key="Web3" value="Web3" />
                <option key="UI" value="UI" />
                <option key="Web Dev" value="Web Dev" />
                <option key="Programming" value="Programming" />
              </datalist>

              <br></br>
              <label>Email (optional)</label>
              <br></br>
              <input
                type="email"
                placeholder="Type your email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              ></input>
              <br></br>

              <div className="btnContainer">
                <button
                  type="submit"
                  className="btn margin8"
                  onClick={() => setPopup(!popup)}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default Contribute;
