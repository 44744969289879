import mixpanel from "mixpanel-browser";
import React from "react";

function Navbar({ popup, setPopup }) {
  var day = ((date) => {
    return Math.floor(
      (date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24
    );
  })(new Date());

  const navClick = () => {
    mixpanel.track("contributed");
    setPopup(!popup);
  };

  var streak = day - 59;

  return (
    <div className="header">
      <div className=" siteWrapper">
        <nav>
          <div className="logo">#Share x{streak}</div>
          {/* <img src={logo}></img> */}
          <button className="navBtn" onClick={() => navClick()}>
            Contribute
          </button>
        </nav>
      </div>
    </div>
  );
}

export default Navbar;
