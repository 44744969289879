import React from "react";
import Card from "./card";
import { useState, useEffect } from "react";

function AllCards() {
  const [data, setData] = useState([]);
  const [toggle, setToggle] = useState(true);
  const [visible, setVisible] = useState([]);
  const [allTypes, setAllTypes] = useState([]);

  const id = "16yF9-V4WxYNVw9SkY09iwnVX5DyxCd11GOBrHkTMNzI";
  var url = `https://docs.google.com/spreadsheets/d/${id}/gviz/tq?`;

  useEffect(() => {
    fetch(url)
      .then((res) => res.text())
      .then((rep) => {
        const datas = JSON.parse(rep.substring(47).slice(0, -2));

        // selecting day
        var day = ((date) => {
          return Math.floor(
            (date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24
          );
        })(new Date());

        setData(datas.table.rows[day].c);

        let temp = [];
        for (let i = 0; i < datas.table.rows[day].c.length; i = i + 3) {
          if (datas.table.rows[day].c[i]?.v != null) {
            temp.push(datas.table.rows[day].c[i]?.v);
          }
        }
        temp = [...new Set(temp)];

        setAllTypes(temp);
        setToggle(!toggle);
      });
  }, []);

  function renderCards(data) {
    let temp = [];
    for (let i = 0; i < data.length; i = i + 3) {
      temp.push(<Card type={data[i]} title={data[i + 1]} url={data[i + 2]} />);
    }
    return temp;
  }

  const [filter, setFilter] = useState("All");

  useEffect(() => {
    setFilter("All");
    sortCards("All");
  }, [toggle]);

  const toggler = (Type) => {
    setFilter(Type);
    sortCards(Type);
  };

  function sortCards(Type) {
    let temp = [];
    if (Type === "All") {
      for (let i = 0; i < data.length; i = i + 3) {
        if (data[i] != null) {
          temp.push(data[i]?.v);
          temp.push(data[i + 1]?.v);
          temp.push(data[i + 2]?.v);
        }
      }
      setVisible(temp);
    } else {
      for (let i = 0; i < data.length; i = i + 3) {
        if (data[i]?.v === Type) {
          temp.push(data[i]?.v);
          temp.push(data[i + 1]?.v);
          temp.push(data[i + 2]?.v);
        }
      }
      setVisible(temp);
    }
  }

  return (
    <section>
      <div className="siteWrapper">
        <div className="categoryContainer">
          <button
            onClick={(() => toggler("All"), () => setToggle(!toggle))}
            className={filter === "All" ? "categoryActive" : "category"}
          >
            All
          </button>
          {allTypes.map((Type) => (
            <button
              onClick={() => toggler(Type)}
              className={filter === Type ? "categoryActive" : "category"}
            >
              {Type}
            </button>
          ))}
        </div>
        <div className="cards">{renderCards(visible)}</div>

        {/* Product Hunt badge  */}
        <div className="productHuntBadge"
          
        >
          <a
            href="https://www.producthunt.com/posts/share-4?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-share&#0045;4"
            target="_blank"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=334097&theme=dark"
              alt="&#0035;Share - Explore&#0032;something&#0032;new&#0032;every&#0032;twenty&#0045;four&#0032;hours&#0046; | Product Hunt"
              style={{
                width: "250px",
                height: "54px",
                
              }}
            />
          </a>
        </div>
        {/* Product Hunt badge */}
      </div>
    </section>
  );
}

export default AllCards;
