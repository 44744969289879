import React from "react";
import { useState, useEffect } from "react";

import mixpanel from "mixpanel-browser";

// These are stylesheet
import "./App.scss";
import "./CSS/style.css";

// These are containers
import Navbar from "./container/navbar";
import AllCards from "./container/allCards";
import Contribute from "./container/contribute";
import Footer from "./container/footer";

// This is a page
import Home from "./pages/home";
import Loader from "./container/loader";

function App() {
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    let formpopup = document.getElementById("formPopup");

    if (popup) {
      formpopup.classList.remove("hide");
    } else {
      formpopup.classList.add("hide");
    }
  }, [popup]);

  mixpanel.init("52c1e3c32161298fa34e4aa1769622d0", {
    debug: false,
    ignore_dnt: true,
  });

  return (
    <div className="appContent">
      <Navbar setPopup={setPopup} popup={popup} />
      <Home />

      <AllCards />
      <Contribute setPopup={setPopup} popup={popup} />
      <Footer />
    </div>
  );
}

export default App;
