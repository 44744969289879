import React from "react";

import mixpanel from "mixpanel-browser";

function Footer() {
  const d = new Date();
  let year = d.getFullYear();

  const footerQQ = () => {
    mixpanel.track("footer qq opened");
  };

  return (
    <footer>
      <div className="siteWrapper">
        <div className="footerContainer">
          <div className="footerLeft">
            copyright ©
            <span>
              <a
                className="copyright"
                href="https://quadqode.com"
                target="#"
                onClick={footerQQ}
              >
                quadqode{" "}
              </a>
            </span>
            {year}
          </div>
          <div>
            <a
              href="https://twitter.com/quadqode"
              target="#"
              className="footerLink"
            >
              Twitter
            </a>
            <a
              href="https://github.com/quadqode"
              target="#"
              className="footerLink"
            >
              Github
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
