import React from "react";






function Home() {
  return (
    <div>
      <section>
        <div className="siteWrapper">
          <div className="heroContainer">
          <h1>Explore something new every twenty-four hours</h1>
         
          

          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
